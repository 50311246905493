@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$iconSize: 40px;
$iconSizeSmall: 25px;

.block {
  svg {
    height: $iconSize;
    min-height: $iconSize;
    width: $iconSize;
    min-width: $iconSize;
    cursor: pointer;
  }

  &.small {
    svg {
      height: $iconSizeSmall;
      min-height: $iconSizeSmall;
      width: $iconSizeSmall;
      min-width: $iconSizeSmall;
    }
  }
}
