@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {

}

.heading {
  color: #fff;
  margin: size("gutter--large") 0;
}

.item, .summaryRow {
  display: flex;
  justify-content: space-between;

  background: color("checkout-fg");
  padding: size("gutter");
  border-radius: size("border-radius");
  border: 1px solid color("checkout-fg-border");

  & + & {
    margin-top: size("grid-gutter");
  }
}

.items, .summary {
  background: color("checkout-fg");
  border-radius: size("border-radius");
  padding: size("grid-gutter");
}

.item {
  background: #fff;
}

.left {
  position: static;

  @media (min-width: breakpoint("medium")) {
    display: flex;
  }
}

.right {
  display: flex;
  align-items: center;
}

.image {
  width: 80px;
  height: 80px;
  border-radius: size("border-radius") / 2;

  @media (max-width: breakpoint("medium") - 1px) {
    float: left;
    margin-right: size("gutter--small");
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-left: size("gutter");
  margin-right: size("gutter");
}

.name {
  @include SASHeading();
  margin: 0;
}

.loading {
  padding: size("gutter");
}

.brand {
  color: color("text--muted");
  margin: 0;
}

.price {
  color: color("text--muted");
  font-size: size("font--small");
}

.summaryRow .left .info {
  margin-left: 0;
}

.summaryRow {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #fff;
  padding: size("gutter");
  border-radius: size("border-radius");

  .title {
    @include SASHeading();
    margin-bottom: 0;
  }

  .subTitle {
    color: color("text--muted");
    margin-top: 0;
  }

  & + & {
    margin-top: size("grid-gutter");
  }
}

.accordion {
  background: color("checkout-fg");
  border-radius: size("border-radius");
  overflow: hidden;

  &Header {
    background: #fff;
    font-weight: 800;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: size("gutter--small");
    user-select: none;

    span {
      font-weight: normal;
      color: color("text--muted");
    }
  }

  .closeIcon {
    transition: transform transition("duration--organisms") transition("ease-in");
    width: 24px;
    height: 24px;

    &.open {
      transform: scaleY(-1);
    }

    path {
      fill: color("tertiary");
    }
}

  @media (min-width: breakpoint("tiny-small")) {
    &Header {
      display: none;
    }

    & > div[data-foldable=true] {
      overflow: auto !important;
      max-height: none !important;
    }
  }
}

.otp {
  padding: size("gutter");

  button {
    width: 100%;
  }
}

.paymentMethods {
  padding: size("gutter");

  h2 {
    font-size: size("font--small");
    color: color("text");
  }
}
