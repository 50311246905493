@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$size: 30px;

.block {
  color: #fff;
  border-radius: 50%;
  width: $size;
  height: $size;
  display: flex;
  justify-content: center;
  align-items: center;

  &.level__B { background: color("member-level--member"); }
  &.level__S { background: color("member-level--silver"); color: color("text"); }
  &.level__G { background: color("member-level--gold"); color: color("text"); }
  &.level__D { background: color("member-level--diamond"); color: color("text"); }
  &.level__P { background: color("member-level--pandion"); }
}
