@import "../../../node_modules/@crossroads/ui-components/src/ui-components/FilterMenu/FilterContainer.scss";
@import "theme/ui-components/_variables.scss";

$headerHeight: 60px;
$closeIconMarginRight: -2px;

.block {
  border-bottom: 1px solid color("border--muted");

  &__header {
    & > h3 {
      font-size: size("font");
      color: color("text");
    }

    .closeIcon {
      transform: none;
      transition: transform transition("duration--organisms") transition("ease-ease");
      margin-right: $closeIconMarginRight;

      .open & {
        transform: scaleY(-1);
      }

      path {
        color: color("tertiary") !important;
      }
    }
  }
}

