@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$image-size: 80px;

.items {
  @media (min-width: breakpoint("medium")) {
    display: flex;
    flex-wrap: wrap;
    @include stretch(calc(size("grid-gutter") / 2));
  }
}

.col {
  @media (min-width: breakpoint("medium")) {
    flex: 0 1 50%;
    padding: calc(size("grid-gutter") / 2);
  }

  @media (max-width: (breakpoint("medium") - 1px)) {
    & + & {
      margin-top: size("grid-gutter");
    }
  }
}

.item {
  background: #fff;
  border: 1px solid color("checkout-fg-border");
  padding: size("gutter--mini");
  border-radius: size("border-radius");
  transition: opacity transition("duration--atoms") transition("ease-in");
  box-shadow: 0 3px 4px 0px color("checkout-fg-border");

  &.processing {
    opacity: 0.6;
  }

  &.disabled {
    pointer-events: none;
  }

  & + & {
    margin-top: calc(size("gutter") / 2);
  }


  @media (max-width: (breakpoint("medium") - 1px)) {
    padding-bottom: size("gutter") * 1.1;
  }

  @media (min-width: breakpoint("medium")) {
    display: flex;
    justify-content: space-between;
  }
}

.left {
  flex: 0 0 auto;
}

.right {
  flex: 1 0 auto;
}

.image {
  width: $image-size;
  height: $image-size;
  border-radius: calc(size("border-radius") / 2);
  margin-right: size("gutter--small");

  @media (max-width: (breakpoint("medium") - 1px)) {
    float: left;
  }
}

.explore {
  text-decoration: underline;
}

.info {
  min-height: $image-size;
}

.name {
  display: block;
  color: color("text");
  text-transform: uppercase;
  letter-spacing: font("uc-letter-spacing");
  font-weight: 800;
  line-height: 1.2;
  margin: 0;
}

.brand {
  display: inline-block;
  margin: calc(size("gutter--mini") / 2) 0;
  color: color("text--muted");
  font-size: size("font--small");
}

.price {
  margin: calc(size("gutter--mini") / 2) 0;
  font-size: size("font--small");
  line-height: font("line-height");
  min-height: 44px;

  & > *:last-child {
    color: color("text--muted");
  }
}

.links {
  margin-top: size("gutter--mini");
  text-decoration: underline;
  color: color("primary");
  font-size: size("font--small");

  & > span {
    cursor: pointer;

    @media (max-width: (breakpoint("mini") - 1px)) {
      display: block;

      & + & {
        margin-top: size("gutter--mini");
      }
    }

    @media (min-width: breakpoint("mini")) {
      & + & {
        margin-left: size("gutter");
      }
    }
  }
}

.bundleOptions {
  margin-top: size("gutter--mini");
  font-size: size("font--small");
  color: color("text--muted");
  list-style-type: disc;
  list-style-position: inside;
}

.confirmDialogue {
  width: 500px;
  padding: size("gutter--medium");

  .closeIconWrapper {
    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  button:first-child {
    color: color("text--muted");
  }

  button:last-child {
    color: color("secondary");
  }

  button {
    text-transform: uppercase;
    font-size: size("font");
  }
}
