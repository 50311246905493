@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  line-height: font("line-height");
  padding: size("gutter--small") 0;
  user-select: none;
  cursor: pointer;

  border-bottom: 1px solid color("border");

  .block:first-child & {
    border-top: 1px solid color("border");
  }

  svg {
    flex: 0 0 auto;
    margin-right: size("gutter");

    transition: transform transition("duration--organisms") transition("ease-ease");
    will-change: transform;

    path {
      fill: color("tertiary");
    }

    .open & {
      transform: scaleY(-1);
    }
  }
}

.body {
  padding: size("gutter--small") 0;
}
