@import "theme/ui-components/_variables.scss";

.block {
  svg {
    width: 32px;
    height: 28px;
  }
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: .7rem;
  font-weight: bold;

  color: #fff;
  background: color("negative");
  border-radius: 50%;
  width: 17px;
  height: 17px;

  position: absolute;
  z-index: 1;
  right: -8px;
  top: -9px;
}
