@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.pagination {
  margin-top: size("gutter--large");
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__button {
    width: 36px;
    height: 36px;

    & > svg {
      width: 100%;
      height: 100%;

      path {
        fill: color("tertiary");
      }
    }

    &[disabled] {
      opacity: .5;
    }

    &:nth-of-type(1) {
      transform: rotateZ(90deg);
    }

    &:nth-of-type(2) {
      transform: rotateZ(-90deg);
    }
  }

  &__text {
    font-size: size("font--small");
    color: color("text");
  }
}
