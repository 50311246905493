@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.box {
  width: 100%;
  background: color("body--off");
  padding: size("gutter--small");
  border-radius: size("border-radius");
}

.heading {
  font-size: size("font--large");
  transition: opacity transition("duration--atoms") transition("ease-ease");

  @media (max-width: breakpoint("small") - 1px) {
    margin-bottom: size("gutter--mini");
    font-size: size("font");
  }

  &.processing {
    opacity: 0.5;
  }
}

.pointSelect {
  @media (min-width: breakpoint("small")) {
    display: flex;
    flex: 0 1 auto;
  }
}

.slider {
  flex: 1 0 auto;
  @media (min-width: breakpoint("small")) {
    margin-right: size("gutter--large");
  }

  @media (max-width: breakpoint("small") - 1px) {
    margin-bottom: size("gutter");
  }
}

.sliderLabels {
  display: flex;
  justify-content: space-between;

  @media (max-width: breakpoint("small") - 1px) {
    font-size: size("font--small");
  }
}

.inputs {
  display: flex;
  flex: 0 1 auto;

  & > div {
    width: 50%;
    @media (min-width: breakpoint("small")) {
      width: 110px;
    }
    &:not(:last-child) {
      margin-right: size("gutter--small");
    }
  }
}

.button {
  margin-top: size("gutter--large");

  @media (max-width: breakpoint("small") - 1px) {
    width: 100%;
  }
}
