@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$submitButtonHeight: 54px;

.row {
  &:not(:first-child) { margin-top: size("gutter"); }
  &:not(:last-child)  { margin-bottom: size("gutter"); }

  padding: size("grid-gutter");
  background: color("checkout-fg");
  border-radius: size("border-radius");
}

.left {
  input {
    border: none;
  }
}

.heading, .checkbox {
  color: #fff;
}

.checkbox {
  color: #fff !important;
}

.submitButton {
  width: 100% !important;
  height: $submitButtonHeight !important;

  &Container {
    padding: size("gutter");
  }
}
