@import "../../node_modules/@crossroads/shop-views/src/shop-views/ProductListHero.scss";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.content {
  flex-basis: 100%;
  padding: size("gutter");

  @media (min-width: breakpoint("small")) {
    padding: 0 size("gutter--large") size("gutter--small");
  }
   
}

.text {
  font-size: size("font--medium");
  padding-top: size("gutter--large");

  .title {
    display: none;
  }

  .totalCount, .loadingText {
    span {
      text-transform: uppercase;
      font-weight: 900;
    }
  }
}

.delimeter {
  font-size: 0;
  margin: 0 10px;
  margin-top: 2px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-color: transparent transparent transparent #3f3d3a;
  display: inline-block;
}