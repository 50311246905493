@import "../../node_modules/@crossroads/shop-views/src/shop-views/AdventCalendarHero.scss";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.breadcrumbs {
  background-color: color("hero--background");
  padding: (size("gutter") * 2) 0;
  box-shadow: 0 0 0 100vmax color("hero--background");
  clip-path: inset(0 -100vmax); 

  > span:not(:last-child) {
    font-size: 0;
    margin: 0 10px;
    margin-top: 2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 6px;
    border-color: transparent transparent transparent #3f3d3a;
    display: inline-block;
  }
}

.totalCount {
  font-size: size("font--medium");
  margin: size("gutter--large") 0 size("gutter--small");
}