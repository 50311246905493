@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  @media (min-width: breakpoint("small")) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.box {
  width: 100%;
  background: color("body--off");
  padding: size("gutter--small");
  border-radius: size("border-radius");
}

.heading {
  font-size: size("font--large");

  @media (max-width: breakpoint("small") - 1px) {
    font-size: size("font");
    margin-bottom: size("gutter--mini");
  }
}

.pointSelect {
  @media (min-width: breakpoint("small")) {
    display: flex;
    flex: 0 1 auto;
  }
}

.slider {
  flex: 1 0 auto;
  margin-right: size("gutter--large");

  @media (max-width: breakpoint("small") - 1px) {
    margin-right: 0;
    margin-bottom: size("gutter");
  }
}

.sliderLabels {
  display: flex;
  justify-content: space-between;

  @media (max-width: breakpoint("small") - 1px) {
    font-size: size("font--small");
  }
}

.input {
  flex: 0 1 auto;
}

.button {
  margin-top: size("gutter--large");

  @media (max-width: breakpoint("small") - 1px) {
    width: 100%;
  }
}
