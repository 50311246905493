/*
  fonts.scss
  ------------------------------------------------------------

  Custom font faces goes here.

  Format them like this:

  @font-face {
    font-family: "Name";
    font-weight: 400;
    font-style: normal;
    src: url("../../static/assets/fonts/fontname.woff")  format("woff"),
         url("../../static/assets/fonts/fontname.woff2") format("woff2"),
         url("../../static/assets/fonts/fontname.ttf")   format("truetype");
  }

  Use the same family name but different font weights for
  different weights of the same family. In other words; DON'T
  name font faces with another weight as "name-regular",
  "name-bold" or similar. Just use the same name but a
    different weight.
*/


@font-face {
  font-family: 'Scandinavian';
  src: url('assets/fonts/ScandinavianNew-Regular.eot');
  src: url('assets/fonts/ScandinavianNew-Regular.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/ScandinavianNew-Regular.woff2') format('woff2'),
    url('assets/fonts/ScandinavianNew-Regular.woff') format('woff'),
    url('assets/fonts/ScandinavianNew-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Scandinavian';
  src: url('assets/fonts/ScandinavianNew-Bold.eot');
  src: url('assets/fonts/ScandinavianNew-Bold.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/ScandinavianNew-Bold.woff2') format('woff2'),
    url('assets/fonts/ScandinavianNew-Bold.woff') format('woff'),
    url('assets/fonts/ScandinavianNew-Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'Scandinavian';
  src: url('assets/fonts/ScandinavianNew-Black.eot');
  src: url('assets/fonts/ScandinavianNew-Black.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/ScandinavianNew-Black.woff2') format('woff2'),
    url('assets/fonts/ScandinavianNew-Black.woff') format('woff'),
    url('assets/fonts/ScandinavianNew-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

// Somehow some part of our build system likes to ignore the font if it's not used in the same file
.foo { font-family: "Scandinavian"; }
