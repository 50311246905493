@import "theme/ui-components/_variables.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    color: color("text--muted");
    width: 20px;
    height: 20px;
  }
}

.title {
  margin-bottom: 0;
  color: color("text");
  margin-right: size("gutter--small");

  @media (max-width: breakpoint("tiny-small") - 1px) {
    font-size: size("font");
  }
}

.userTierInfo {
  margin: size("gutter--mini") 0;

  p {
    color: color("text--muted");
  }
}

.edit {
  cursor: pointer;
  color: color("primary");
  text-decoration: underline;

  &.disabled {
    color: color("border");
    cursor: not-allowed;
  }
}

.body {
  padding-top: size("gutter--small");
}

.tooltip {
  line-height: font("line-height");
}
