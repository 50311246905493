@import "theme/ui-components/_variables.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    color: color("text--muted");
    width: 20px;
    height: 20px;
  }
}

.heading {
  margin-bottom: 0;
}

.subHeading {
  margin-top: size("gutter--mini");
  margin-bottom: 0;
  color: color("text--muted");
}

.body {
  margin-top: size("gutter");
}

.footer {
  display: flex;
  justify-content: space-between;

  & > * {
    margin-top: 0;
  }
}

.pointSelect {
  @media (min-width: breakpoint("small")) {
    display: flex;

    &>div:last-child {
      margin-left: size("gutter--large");
    }
  }

  @media (max-width: breakpoint("small") - 1px) {
    &>div:last-child {
      margin-top: size("gutter--large");
    }
  }
}

.slider {
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sliderLabels {
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: color("text--muted");
}

.remainder {
  display: block;
  text-align: right;
  font-weight: 100;
  transition: opacity transition("duration--atoms") transition("ease-in");

  .processing & {
    opacity: 0.5;
  }
}
