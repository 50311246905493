@import "theme/ui-components/_variables.scss";

$submitButtonHeight: 54px;

.box {
  background: #fff;
  border-radius: size("border-radius");
  padding: size("gutter");

  h3 {
    color: color("text");
  }

  hr {
    border-color: color("checkout-fg-border");
  }
}

.submitButton {
  width: 100% !important;
  height: $submitButtonHeight !important;

  &Container {
    padding: size("gutter");
  }
}
