@import "../../node_modules/@crossroads/ui-components/src/ui-components/AdventCalendar.scss";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";
$paddingX: 10px;
$paddingY: 5px;

.door {
  &Text {
    h3, h2 {
      margin-top: 0;
      margin-bottom: .5rem;
    }

    padding: $paddingY $paddingX;
  }
}

.button {
  pointer-events: none;

  &:hover,
  .door:hover & {
    background: color("primary--dark") !important;
  }

  .door:active & {
    background-color: color("primary--dark") !important;
    background-size: 100%;
    transition: background 0s;
  }
}

