@import "theme/ui-components/_variables.scss";

$paddingX: 25px;
$paddingY: 15px;
$campaignBackground: rgb(232, 30, 20);

:export {
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;

  &:not(.block__wrap) {
    border-radius: size("border-radius");
  }

  img {
    object-fit: cover;
    width: 100%;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      position: absolute;
    }
  }
}

.image {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.blockLink {
  height: 100%;
  text-decoration: none !important;

  .contentWrapper {
    height: 100%;
    justify-content: center;

    .textWrapper {
      padding: size("gutter--large") size("gutter--large") * 3;
      background: rgba(color("tertiary"), .8);
    }

    h1, h2 {
      padding: 0;
      background: transparent;
    }
  }
}

.campaign {
  background-color: $campaignBackground;
  color: #fff;
  display: flex;
  position: absolute;
  z-index: 1;
  bottom: 265px;

  span {
    padding: 5px 20px;
    font-size: size("font--mini");
    font-weight: 700;
  }
}

.contentWrapper, .blockLink {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: size("gutter");

  .textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: color("secondary");
  }

  h1, h2, h3 {
    display: inline-flex;
    background: color("secondary");
    color: #fff;
    padding: $paddingY $paddingX;
    margin: 0;
    white-space: pre-line;
    border-radius: 2px;
    text-align: center;
  }

  h1 {
    font-size: 2.5rem;
  }

  h1:last-child {
    margin-bottom: size("gutter--mini");
  }

  h2 {
    font-size: size("font--huge");
  }

  h3 {
    font-size: size("font--medium");
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    h1 {
      font-size: size("font--large");
    }

    h2 {
      font-size: size("font--medium");
    }

    h3 {
      font-size: size("font");
    }
  }

  @media (max-width: (breakpoint("tiny") - 1px)) {
    h1 {
      font-size: size("font--medium");
    }

    h2 {
      font-size: 1.125rem;
    }

    h3 {
      font-size: size("font--small");
    }
  }

  & h1, h2, h3 {
    text-transform: uppercase;
    font-weight: 800;
  }

  & > *:not(a) + *:not(a) {
    margin-top: size("grid-gutter") !important;
  }

  & > a {
    margin-top: size("gutter--large");
  }
}

.blockSplitHero {
  border: 1px solid color("border");
  align-items: flex-end;

  .spacer {
    padding: 0 size("gutter--large") size("gutter--small");
    width: 100%;

    a {
      text-decoration: none;
    }

    .contentWrapper {
      background: #fff;
      border-radius: size("border-radius");

      h2 {
        font-size: 1.75rem;
        margin: 0 !important;
        padding: 0 !important;
      }

      .ctaLink {
        margin-top: size("gutter--small");

        svg {
          margin-top: -3px;
          margin-left: 5px;
        }
      }
    }
  }

  h1, h2, h3 {
    background: transparent;
    color: color("tertiary");
  }

  .image {
    margin-bottom: 6rem;
  }
}
