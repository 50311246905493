@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.link {
  text-decoration: none !important;

  &:active:hover {
    opacity: 1;
  }

  &:focus:focus-visible {
    box-shadow: none !important;
  }
}