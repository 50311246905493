@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$imageWidthIncrease: 10px;

:export {
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.block {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  overflow: hidden;
  text-decoration: none;
  transition: border-color transition("duration--molecules") transition("ease-ease"),
              boxh-shadow transition("duration--molecules") transition("ease-ease");

  border: 1px solid color("checkout-fg-border");
  border-radius: size("border-radius");

  &:not(.dummy):hover {
    text-decoration: none;
    border-color: color("text--muted");
    box-shadow: 0px 4px 8px 0px rgba(213,212,209,1);

    .hoverFG {
      opacity: .2;
    }

    .imageWrapper {
      transform: scale(1.01);
    }

    .name {
      text-decoration: underline;
    }
  }
}

.hoverFG {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  pointer-events: none;
  opacity: 0;
  transition: opacity transition("duration--molecules") transition("ease-ease"),
}

.header {
  overflow: hidden;
  background: color("disabled");
}

.imageWrapper {
  transition: transform transition("duration--molecules") transition("ease-ease");
}

.image {
  position: absolute;
  width: 100%;
}

.body {
  background: #fff;
  padding: size("gutter");
  text-align: left;
  // use static value to allow dummy cards
  // TODO: calculate in SASS?
  height: 125px;
  flex: 1 0 auto;
}

.top {
  .wishlistIcon {
    position: absolute;
    bottom: 3px;
    top: auto;
    right: 5px;
    color: color("tertiary");

    @media (min-width: breakpoint("small")) {
      right: -5px;
    }
  }
}

.brandRow {
  display: flex;
  justify-content: space-between;

  margin-bottom: size("gutter--mini") / 2;

  @media (max-width: breakpoint("tiny")) {
    font-size: size("font--small");
  }
}

.brand {
  color: color("text--muted");
  text-overflow: ellipsis;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  padding-right: size("gutter--mini");
}

.accrual {
  flex: 1 0 auto;
  text-align: right;
  font-size: size("font--small");
  color: color("text--muted");
  min-width: 0;
}

.name {
  font-weight: 700;
  color: color("text");
  font-size: size("font--medium");
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  line-height: font("line-height");
  width: calc(100% - #{size("gutter") * 2});

  margin-bottom: size("gutter--mini") / 2;

  @media (min-width: breakpoint("small")) {
    width: calc(100% - #{size("gutter") * 1.5});
  }

  @media (max-width: breakpoint("tiny")) {
    font-size: size("font--small");
  }
}

.brand, .price {
  line-height: 1;
}

.price {
  font-size: size("font--small");
  color: color("text");
  margin-top: 0;
  margin-bottom: 0;
  line-height: font("line-height--heading");

  @media (max-width: breakpoint("tiny")) {
    font-size: size("font--mini");
  }
}

.badges {
  position: absolute;
  bottom: size("gutter--small");
  left: size("gutter--small");

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * {
    border-radius: size("border-radius");
    background: color("tertiary");
    color: #fff;
    font-size: size("font--small");
    padding: size("gutter--mini") / 2.7 size("gutter--mini") * 1.3;
  }

  & > * + * {
    margin-top: size("gutter--mini");
  }
}

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.dummy {
  .image {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  span, p {
    display: block;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }

  .name {
    max-width: 50%;
  }

  .brand {
    max-width: 53%;
  }

  .price {
    max-width: 42%;
  }
}

.colors {
  display: flex;
  justify-content: center;
  position: absolute;
  top: size("gutter--mini");
  z-index: 1;
  left: 0;
  right: 0;

  & > div {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    margin: 0 size("gutter--mini") / 2;
  }
}
