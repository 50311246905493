@import "../ui-components/_variables.scss";
@import "../ui-components/_mixins.scss";

/*
  Global typography rules
*/

$block: "typography";

@mixin headings($sizes...) {
  $uniqueId: unique-id();

  %#{$uniqueId} {
    @content;
  }

  @each $size in $sizes {
    h#{$size},
    .h#{$size} {
      @extend %#{$uniqueId};
    }
  }
}

:global {
  @include headings(1, 2, 3) {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: font("uc-letter-spacing");
    color: color("tertiary");
  }

  @include headings(1) {
    font: 800 #{size("font--enormous")}/font("line-height--heading") #{font("header")};
    @include vertical-margins(size("gutter--large"));
  }

  @include headings(2) {
    font: 800 #{size("font--huge")}/font("line-height--heading") #{font("header")};
    @include vertical-margins(size("gutter--large"));
  }

  @include headings(3) {
    font: 800 #{size("font--medium")}/font("line-height--heading") #{font("header")};
    @include vertical-margins(size("gutter"));
  }


  @include headings(4, 5, 6) {
    margin: 0;
    font: 700 #{size("font--large")}/font("line-height--heading") #{font("body")};
    @include vertical-margins(size("gutter--large"));
  }

  @include headings(4) {
    font: 400 #{1em}/font("line-height") #{font("body")};
    font-size: size("h4");
    margin-bottom: size("gutter");

    @media (max-width: breakpoint("small")) {
      font-size: size("h4--mobile");
    }
  }

  @include headings(5) {
    font-size: size("h5");
    @include vertical-margins(size("gutter--small"));
  }

  h2, .h2 {
    @media (max-width: breakpoint("small")) {
      font-size: size("font--medium");
    }
  }

  h3, .h3 {
    @media (max-width: breakpoint("small")) {
      font-size: 1.125rem;
    }
  }

  %anchor {
    color: color("primary");
    text-decoration: underline;
  }

  p,
  .paragraph {
    line-height: font("line-height");
    @include vertical-margins(size("gutter"));

    &,
    > i,
    > b,
    > em,
    > strong {
      > a {
        @extend %anchor;
      }
    }
  }

  li {
    line-height: font("line-height");
  }

  .lead {
    font-size: font("size--large");
  }

  .anchor {
    @extend %anchor;
  }

  mark {
    padding: .2em;
    background: color("mark");
  }

  strong, b {
    font-weight: 700;
  }

  .#{$block} {
    ol, ul {
      line-height: font("line-height");
      @include vertical-margins(size("gutter--small"));
      list-style-position: inside;

      ul {
        list-style-type: circle;
      }
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
      line-height: font("line-height");
    }

    table {
      @include vertical-margins(size("gutter--small"));
    }

    td,
    th {
      text-align: left;
      vertical-align: baseline;
      line-height: font("line-height--heading");
    }

    tr {
      &:not(:first-child) {
        td, th {
          padding-top: .5em;
        }
      }

      &:not(:last-child) {
        td, th {
          padding-bottom: .5em;
        }
      }
    }


    td:not(:first-child) {
      padding-left: size("gutter--small");
    }

    [style*="font-size"] {
      font-size: inherit !important;
    }

    $column-width: size("gutter--section");

    .columns {
      @media (min-width: breakpoint("small")) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax($column-width, 1fr));
        column-gap: size("gutter--large");
        row-gap: size("gutter--large");
      }
    }
    .sticky {
      @media (min-width: breakpoint("small")) {
        position: sticky;
        top: size("header-height") + size("header-margin");
      }
    }
  }
}
