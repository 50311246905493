@import "theme/ui-components/_variables.scss";

.block {
  top: size("header-height");
  background: color("secondary");
  color: white;
  width: 100%;
  height: size("banner-height");
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    text-transform: uppercase;
    font-weight: 800;
  }

  @media (min-width: breakpoint("tiny")) {
    display: none;
  }
}
