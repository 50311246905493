@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

:export {
  animationDuration: strip-unit(transition("duration--organisms"));
}

$height: size("header-height");

.block {
  @media (min-width: breakpoint("medium")) {
    display: none !important;
  }

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: z-index("overlay");
  transition: transform transition("duration--organisms") transition("ease-out");

  transform: translateX(100%);
  opacity: 0;

  &.open {
    transform: translateX(0%);
    opacity: 1;
  }

  &.open.subNavOpen {
    transform: translateX(-100%);
  }

  &.isOpening {
    opacity: 1;
  }

  &.isClosing {
    opacity: 1;
    transform: translateX(100%) !important;
  }
}

.hamburgerMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: color("OOC--color");

  height: 100vh;
  overflow-y: auto;
}

.topMenu {
  width: 100%;
  flex: 0 0 auto;
}

.bottomMenu {
  width: 100%;
  flex: 1 0 auto;
  color: color("text");
  padding-top: size("gutter--large") * 1.5;
  padding-bottom: size("gutter--large") * 1.5;

  .row {
    display: flex;
    align-content: center;
    justify-content: space-between;

    &:not(:last-child) {
      padding-bottom: size("gutter--large");
    }

    & > div:last-child {
      text-align: right;
    }
  }

  .link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: size("header-height");
  min-height: size("header-height");
  width: 100%;
  border-bottom: 1px solid color("border");
  font-weight: 800;
  font-size: size("font--medium");
  text-transform: uppercase;

  span {
    color: color("tertiary");
  }
}

.item[class] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: size("font--small");
  font-weight: 800;
  border-bottom: 1px solid color("border");
  padding: 0 size("gutter--small");
  height: size("mobile-menu-row-height");
  color: color("text");
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;

  user-select: none;
}

.item {
  &:hover {
    text-decoration: none;
  }

  span {
    display: flex;
    align-items: center;
  }

  svg + span {
    margin-left: size("gutter--mini");
  }

  svg {
    color: color("text");
    width: 18px;
    height: 18px;
  }

  &.active svg {
    color: color("primary");
  }
}

.chevron {
  width: 24px !important;
  height: 24px !important;
  transform: rotate(-90deg);

  path {
    fill: color("tertiary");
  }
}

.close {
  width: size("mobile-header-icon");
  height: size("mobile-header-icon");
}

.searchIcon {
  height: 24px !important;
  width: 24px !important;
  margin-left: 2px;
}

