@import "../../node_modules/@crossroads/ui-components/src/ui-components/Button.scss";

$button-height--medium: 44px;
$border-box-shadow: 0 0 0 2px hsl(0 0% 100%),0 0 0 4px hsl(239 100% 37%);

.button {
  border: none;

  &.primary {
    @include commonButton(
      $background: color("primary--alt"),
      $color: color("secondary"),
      $br: 35px,
      $radial: false,
    );

    box-shadow: none;
    font-size: size("font");
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: font("uc-letter-spacing");

    &:hover {
      background: color("primary--dark") !important;
      background-size: inherit !important;;
      transition: inherit !important;;
    }

    &:active {
      background-size: inherit !important;
      transition: inherit !important;
      background: color("primary--alt-light") !important;
      border: none !important;
      color: color("secondary") !important;
    }

    &:disabled {
      background: color("disabled") !important;
      border-color: color("disabled") !important;
      color: rgba(color("text"), .35) !important;
    }

    &:focus:focus-visible {
      box-shadow: $border-box-shadow !important;
    }
  }

  &.secondary {
    @include commonButton(
      $color: color("secondary"),
      $br: 35px,
      $borderStyle: 2px solid,
      $borderColor: color("primary--alt"),
      $radial: false,
    );

    transition: background 0.1s;
    font-size: size("font");
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: font("uc-letter-spacing");

    &:hover {
      background-color: color("primary--dark") !important;
      border: none !important;
      background-size: inherit !important;
      transition: inherit !important;;
    }

    &:active {
      background-color: color("primary--alt-light") !important;
      background-size: inherit !important;
      transition: inherit !important;
      border: none !important;
      color: color("secondary") !important;
    }

    &:active:hover {
      opacity: 1 !important;
    }

    &:focus:focus-visible {
      background-color: color("primary--alt") !important;
      box-shadow: $border-box-shadow !important;
    }
  }

  &.secondarydark {
    @include commonButton(
      $background: #486fe3,
      $color: #fff,
      $br: size("border-radius"),
      $radial: false,
    );

    transition: background 0.1s;
    font-size: size("font");
  }

  &.blank {
    padding: size("gutter--mini") * 1.1 size("gutter--large");
    color: color("primary");

    &:enabled:active:hover {
      text-decoration: underline;
    }
  }

  &.negative {
    @include commonButton(
      $background: #fff,
      $color: color("primary"),
      $br: size("border-radius"),
      $radial: false,
    );

    transition: background 0.1s;

    &:enabled {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.ghost {
    font-size: size("font");
    font-weight: 800;
    text-transform: uppercase;
    border: 2px solid color("primary");
  }
}

.hasVariant {
  &.size {
    &__large {
      padding: size("gutter--mini") * 1.17 size("gutter--large") size("gutter--mini") * 1.1;

    }

    &__medium {
      height: $button-height--medium;
      padding: 0 size("gutter");
    }

    &__small {
      padding: size("gutter--mini") * .65 size("gutter--small") size("gutter--mini") * .5;
      font-size: size("font");
    }
  }

  &.link {
    padding: 0;
      //@extend %commonTransition;
    color: color("primary");
    text-decoration: none;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(#fff, 0);
    cursor: pointer; /* [4] */
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: 0;
    }

    &:active:hover {
      opacity: .5;
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }

  &.ghost_white {
    @include commonButton(
      $color: #fff,
      $background: inherit,
      $borderColor: #fff,
      $br: size("border-radius"),
    );

    font-size: size("font");

    &:enabled {
      &:hover {
        border-color: darken(#fff, 10);
        color: darken(#fff, 10);
      }

      &:active:hover {
        border-color: darken(#fff, 20);
        color: darken(#fff, 20);
      }

      html[data-whatintent="keyboard"] &:focus {
        border-color: darken(#fff, 10);
        color: darken(#fff, 10);
      }
    }
  }
}


