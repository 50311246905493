@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  @media (min-width: breakpoint("medium")) {
    width: 50%;
  }
}

.languageSwitcher {
  @media (min-width: breakpoint("tiny")) {
    width: 50%;
  }
}