.optionRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.color .optionLabel {
    display: none;
  }

  @media (max-width: breakpoint("small") - 1px) {
    align-items: stretch;
  }
}
