@import "../../node_modules/@crossroads/ui-components/src/ui-components/OffCanvasFilterMenu.scss";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$headerHeight: size("header-height");
$submitButtonHeight: 72px;
$closeButtonSideLength: 36px;
$closeButtonOffsetRight: -8px;

.header {
  flex: 0 0 auto;
  height: $headerHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: size("gutter") size("gutter--large");

  & > h2 {
    font-size: size("font--medium");
    color: color("text");
    margin: 0;
  }

  .closeButton {
    width: $closeButtonSideLength;
    height: $closeButtonSideLength;
    right: $closeButtonOffsetRight;

    & > svg {
      color: color("tertiary");
      width: 30px;
      height: 30px;
    }
  }
}
