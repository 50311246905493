@import "theme/ui-components/_variables.scss";

.block {
  display: flex;
  flex-direction: column;
  background-color: color("body--off");
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: size("gutter--small") size("gutter--medium");
  cursor: pointer;
  color: color("text");
  border-top: 1px solid color("checkout-fg-border");
  background: #fff;

  &:last-of-type {
    border-bottom: 1px solid color("checkout-fg-border");
  }

  &:hover {
    background: color("body--off");
  }

  &Current {
    background: color("body--off");
  }

  .flag {
    flex: 0 0 30px;
    margin-right: size("gutter");
    width: 24px;
    height: 24px;
  }

  .tick {
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-left: size("gutter--mini");
    color: color("tertiary");
  }

  span {
    display: block;
    text-align: left;
    flex: 1 0 auto;
  }
}
