@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  display: flex;
  justify-content: center;

  width: 480px;
  padding: size("gutter--large") 0;
  @media (max-width: 480px - 1px) {
    width: 100%;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: size("gutter--mini");
  }

  max-width: 400px;
  @media (max-width: 400px - 1px) {
    width: 100%;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 auto;

  margin-top: size("gutter");
}

.button {
  margin-top: size("gutter--small");
  width: 100%;
}

.requestNewCodeTitle {
  margin-bottom: size("gutter--mini");
}

.requestNewCode {
  margin-top: 0;
  margin-bottom: 0;
  color: color("primary");
  text-decoration: underline;
  cursor: pointer;
}

.customerService {
  display: inline-block;
  margin-top: size("gutter--large") * 2.7;
  text-decoration: underline;
}

.modal {
  display: flex;
  justify-content: center;
}
