@import "ui-components/_variables.scss";

:export {
  timeout: strip-unit(transition("duration--organisms"));
}

.block {
  backface-visibility: hidden;
  transition: max-height transition("duration--organisms") transition("ease-ease");
}
