@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$blockHeight: 350px;
$blockHeightSmall: 220px;
$imageOffSetSmall: -50px;

.block {
  background: color("body");
  overflow: hidden;
  padding-bottom: size("gutter");

  &__full {
    height: $blockHeight;
    padding-bottom: 0;
    background: color("hero--background");
    margin-bottom: size("gutter--small");
  }

  @media (max-width: breakpoint("tiny") - 1px) {
    padding-bottom: size("gutter--small");

    &__full {
      background: color("body");
      height: auto;
      padding-bottom: size("gutter--small");
      margin-bottom: 0;
    }
  }
}

.wrapper {
  height: 100%;
  max-height: 100%;
}

.body {
  position: relative;
  height: 100%;
  max-height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;

  &__left {
    display: flex;
    flex-direction: column;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    width: 100%;

    &.absolute  {
      position: absolute;
      width: auto;

      @media (max-width: breakpoint("tiny") - 1px) {
        position: relative;
        width: 100%;
      }
    }
  }

  .breakcrumbs {
    margin-top: size("header-margin");

    &.fullWidth {
      margin-bottom: size("gutter--large");
    }
  }
}

.text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .titleWrapper {
    display: flex;
    justify-content: center;
    font-size: size("font--medium");

    .title {
      margin: 0;
      text-transform: uppercase;
      font-weight: 900;
    }

    @media (max-width: breakpoint("tiny") - 1px) {
      flex-direction: column;

      .title {
        margin-top: size("grid-gutter");
      }
    }
  }


  .description {
    max-width: 50%;
  }

  @media (max-width: breakpoint("tiny") - 1px) {
    &:not(.fullWidth) .description {
      max-width: none;
    }
  }

  &.fullWidth {
    .description {
      max-width: 90ch;
    }
  }
}

.image {
  flex: 0 1 auto;
  width: auto;
  height: 100%;
  bottom: -20%;

  @media (max-width: breakpoint("small") - 1px) {
    height: 100%;
    right: $imageOffSetSmall;
  }

  @media (max-width: breakpoint("tiny") - 1px) {
    display: none;
  }

  & > * {
    height: 100%;
    width: auto;
    max-width: none;
  }
}
