@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$grid-gutter: size("gutter--mini") / 2;

.fields {
  @media (min-width: breakpoint("tiny")) {
    display: flex;
    @include stretch(size("gutter--mini"));

    & > * {
      flex: 1 0 auto;
      padding: 0 size("gutter--mini");
    }
  }

  @media (max-width: breakpoint("tiny") - 1px) {
    & > *:last-child {
      margin-top: $grid-gutter * 2;
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: breakpoint("tiny") - 1px) {
    flex-direction: column;
    justify-content: stretch;

    button {
      width: 100%;
    }
  }

  margin-top: size("gutter");
}

.info {
  display: flex;
  align-items: center;

  @media (max-width: breakpoint("tiny") - 1px) {
    padding-bottom: size("gutter--small");
  }

  span {
    font-size: size("font--mini");
    color: color("text--muted");
  }

  svg {
    margin-right: size("gutter--small");
  }
}
