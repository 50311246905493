@import "theme/ui-components/_variables.scss";

$logoMainSize: size("logo-height");
$logoMainSizeSmall: size("logo-height--small");

.block {
  display: flex;
  align-items: center;
}

.logoMain {
  width: $logoMainSize;
  height: $logoMainSize;
}

.eurobonus {
  font-weight: 800;
  text-transform: uppercase;
  margin-left: size("gutter--small") !important;

  @media (max-width: breakpoint("tiny") - 1px) {
    display: none;
  }
}
