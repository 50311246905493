@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$spacing: size("gutter--large") * 3;
$manufacturerLogoWidth: 180px;
$manufacturerLogoHeight: 108px;

.split {
  @media (min-width: breakpoint("small")) {
    @include stretch();
    @include clearfix();
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.breadcrumbs {
  margin-bottom: size("gutter--large");
}

.header {
  &__outer {
    margin-bottom: size("gutter");
    display: block;

    @media (min-width: breakpoint("small")) {
      display: none;
    }
  }

  &__inner {
    display: none;
    margin-bottom: size("gutter");

    @media (min-width: breakpoint("small")) {
      display: block;
    }
  }
}

.top {
  display: flex;
  justify-content: space-between;
  padding-right: size("gutter--large") * 1.5;
}

.name {
  display: block;
  font-weight: 800;
  color: color("tertiary");
  margin-bottom: size("gutter--mini");
  font-size: size("font--huge");
  margin-top: size("gutter--mini");
}

.brand {
  display: inline-block;
  color: color("primary");
  font-size: size("font--medium");
  text-decoration: underline;
}

.goto {
  text-decoration: underline;
}

.left {
  @media (min-width: breakpoint("small")) {
    align-items: flex-start;
    padding: 0 size('grid-gutter') / 2;
    float: left;
    width: 50%;
    position: sticky;
    top: (size("header-height") + (size("header-margin") / 2));
  }
}

.right {
  @media (min-width: breakpoint("small")) {
    padding: 0 calc(size('grid-gutter') / 2);
    float: right;
    width: 50%;
    padding-left: $spacing;
  }
}

.qtyPicker,
.addToCart,
.login,
.options,
.options > *  {
  @media (max-width: (breakpoint("small") - 1px)) {
    width: 100%;
  }
}

.qtyPicker {
  margin-bottom: size("gutter--large");

  @media (min-width: breakpoint("small")) {
    width: 135px;
  }
}

.priceContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  margin-bottom: size("gutter");
}

.price {
  line-height: font("line-height--heading");

  & > div:first-child {
    font-size: size("font--medium");
  }

  & > div:last-child:not(:first-child) {
    font-size: size("font--small");
    color: color("text--muted");
  }
}

.accrual {
  display: flex;
  align-items: center;
  color: color("text--muted");

  // Make up for line-height on price
  margin-top: 5px;

  & > *:first-child {
    margin-right: size("gutter--mini");;
  }

  & > *:last-child {
    top: 1px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.gutter {
  padding-top: size("gutter");
}

.mainPayment {
  font-size: size("font--medium");
  margin: 0;
}

.secondaryPayment {
  display: block;
  margin-top: 0;
  font-size: size("font--small");
  color: color("text--muted");

  @media (max-width: (breakpoint("small") - 1px)) {
    margin-bottom: size("gutter") !important;
  }
}

.addToCartButton, .login {
  margin-top: size("gutter--large") !important;
  margin-bottom: size("gutter") !important;

  @media (max-width: (breakpoint("tiny") - 1px)) {
    width: 100%;
  }
}

.addToCart h3 {
  font-family: #{font("body")};
  font-weight: bold;
  font-size: size("font");
  margin-bottom: size("gutter--mini");
}

.section {
  &Header {
    h2 {
      font-size: size("font--medium");
      color: color("text");
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 800;
    }

    margin-top: size("gutter--large");

    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;

    padding-bottom: size("gutter--small");
    border-bottom: 1px solid color("border--muted");

    svg {
      transition: transform transition("duration--organisms") transition("ease-ease");

      width: 24px;
      height: 24px;

      margin-right: size("gutter--small");

      .section__open & {
        transform: scaleY(-1);
      }

      path {
        fill: color("tertiary");
      }
    }
  }

  &Body {
    padding: size("gutter") 0;
  }
}

.description {
  color: color("text");

  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: size("gutter");
  }

  li + li {
    margin-top: size("gutter--small");
  }

  .manufacturer {
    margin-top: size("gutter--large") * 1.5;

    .logo {
      max-width: $manufacturerLogoWidth;
      max-height: $manufacturerLogoHeight;
      display: inline-block;

      & img {
        max-width: $manufacturerLogoWidth;
        max-height: $manufacturerLogoHeight;
      }
    }
  }
}

.lists {
  @media (min-width: breakpoint("small")) {
    @include stretch();
    @include clearfix();
  }

  padding-top: size("gutter--large") * 2;

  .relatedList {
    width: 100%;

    @media (min-width: breakpoint("small")) {
      padding: 0 calc(size('grid-gutter') / 2);
      float: left;
      width: 50%;
    }
  }

  .historyList {
    @media (min-width: breakpoint("small")) {
      padding: 0 calc(size('grid-gutter') / 2);
      float: right;
      width: 50%;
    }

    @media (max-width: (breakpoint("small") - 1px)) {
      margin-top: size("gutter--large");
    }
  }
}

.hintWrapper {
  min-height: 100vh;
}

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.dummyContent {
  .top {
    height: size("gutter--large");
  }

  .middle {
    height: size("gutter--large");
    margin-top: size("gutter--large");
  }

  .bottom {
    margin-top: size("gutter--large");
    height: 200px;
  }

  .top, .middle, .bottom {
    display: block;
    background-image: linear-gradient(90deg, color("disabled") 0%, color("background") 50%, color("disabled") 100%);
    background-size: 200%;
    animation: shine 1.5s infinite linear;
  }
}

.wishlistIcon {
  position: absolute;
  color: color("tertiary");

  @media (max-width: (breakpoint("small") - 1px)) {
    top: -8px;
    right: -3px;
  }

  @media (min-width: breakpoint("small")) {
    top: -8px;
    right: -9px;
  }
}

.pointsOnly {
  display: flex;
  align-items: center;
  color: color("secondary");

  & > span:first-child {
    display: inline-block;
    width: 7px;
    height: 7px;
    background: color("secondary");
    border-radius: 50%;

    margin-right: size("gutter--mini");
  }
}

.redemptionDisabled {
  color: color("negative");
}

.customCloseIcon {
  path {
    fill: color("tertiary") !important;
  }
}
