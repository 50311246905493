@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$grid-gutter: size("gutter--mini") / 2;

.block {
  h1, p {
    color: #fff;
  }

  padding-bottom: size("gutter--large") * 2;
}

.header {
  max-width: 850px;
  margin-bottom: size("gutter--large") * 1.2;
}

.container {
  @media (min-width: breakpoint("medium")) {
    display: flex;
    align-items: flex-start;
    @include stretch($grid-gutter / 2);
  }
}

.left, .right {
  @media (min-width: breakpoint("medium")) {
    padding: 0 $grid-gutter;
  }
}

.left {
  flex: 1 1 auto;
}

.right {
  flex: 0 0 300px;
  @media (max-width: breakpoint("medium") - 1px) {
    margin-top: $grid-gutter * 2;
  }
}

.outerBox {
  background: color("member-level--diamond");
  padding: size("gutter--mini");
  border-radius: size("border-radius");
}

.radioGroup {
  &:not(:last-child) {
    margin-bottom: size("gutter--mini");
  }

  & > * {
    padding-top: size("gutter--large") !important;
    padding-bottom: size("gutter--large") !important;
    display: flex !important;
    margin-top: 0 !important;

    @media (max-width: breakpoint("small") - 1px) {
      padding-top: size("gutter") !important;
      padding-bottom: size("gutter") !important;
      padding-left: size("gutter--small") !important;
      padding-right: size("gutter--small") !important;
    }

    &:not(:last-child) {
      margin-bottom: size("gutter--mini");
    }
  }

  label {
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: font("uc-letter-spacing");
    font-size: size("font--medium");

    @media (max-width: breakpoint("small") - 1px) {
      font-size: size("font");
    }

    & > div {
      margin-right: size("gutter");
    }
  }
}

.pointSelectionBox {
  font-size: size("font");

  footer h3 {
    margin-bottom: 0;
    font-size: size("font");
  }
}

.box {
  background: #fff;
  padding: size("gutter");
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.24);
  border-radius: size("border-radius");

  & + & {
    margin-top: size("gutter--mini");
  }

  hr {
    border-color: color("checkout-fg-border");
  }
}
