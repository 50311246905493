@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.row {
  @media (min-width: breakpoint("small")) {
    margin-bottom: size("gutter--small") / 2;
    @include stretch(size("gutter--small") / 4);
    @include clearfix();
  }
}

.s100 {
  @media (min-width: breakpoint("small")) {
    padding: 0 size("gutter--small") / 4;
    float: left;
    width: 50%;
  }

  @media (max-width: breakpoint("small") - 1px) {
    margin-bottom: size("gutter--mini");
    width: 100%;
  }

  &.fullWidth {
    width: 100%;
  }
}
