@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  @media (min-width: breakpoint("tiny-small")) {
    display: flex;
    @include stretch();
    @include clearfix();
  }
}

.left {
  @media (min-width: breakpoint("tiny-small")) {
    padding: 0 calc(size("grid-gutter") / 2);
    float: left;
    width: 73%;
  }
}

.right {
  @media (max-width: (breakpoint("tiny-small") - 1px)) {
    margin-top: size("gutter--large");
  }

  @media (min-width: breakpoint("tiny-small")) {
    padding: 0 calc(size("grid-gutter") / 2);
    float: right;
    flex: 0 0 auto;
    width: 280px;
  }

  @media (min-width: breakpoint("small")) {
    padding: 0 calc(size("grid-gutter") / 2);
    float: right;
    flex: 0 0 auto;
    width: 340px;
  }
}
