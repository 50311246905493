@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$background: color("body");
$logo-size: 60px;

.chevron {
  width: 1.65rem;
  height: 1.65rem;
  margin-right: size("gutter--small") * .8;
}

.block {
  background: $background;
  content-visibility: auto;
}

.wrapper {
  padding-top: size("gutter--large");
  padding-bottom: size("gutter--large");
}

.categoriesWrapper {
  @media (max-width: (breakpoint("tiny") - 1px)) {
    display: none;
  }
}

.categories {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: (breakpoint("tiny") - 1px)) {
    display: none;
  }
}

.category {
  padding: 1rem 0;

  &Link {
    display: block;
    color: color("text") !important;
  }

  @media (min-width: breakpoint("mini")) {
    width: 50%;
  }

  @media (min-width: breakpoint("small")) {
    width: 33.33%;
  }

  @media (min-width: breakpoint("large")) {
    width: 25%;
  }
}

.categoryLink {
  text-decoration: none;
}

.subCategoryLink {
  display: block;
  font-size: size("font--small");
  color: color("text");
  margin-bottom: size("gutter--mini");
  text-decoration: none;
}

.logo {
  width: $logo-size;
  height: $logo-size;

  g {
    fill: #fff;
  }
}

.cols {
  display: flex;
  flex-wrap: wrap;

  @include stretch(size("gutter--small"));

  & > div {
    margin-top: size("gutter--large");

    padding: 0 size("gutter--small");

    &:nth-child(1) { flex: 1 0 23%; }
    &:nth-child(2) { flex: 1 0 23%; }
    &:nth-child(3) { flex: 1 0 54%; }

    @media (max-width: (breakpoint("small") - 1px)) {
      &:nth-child(1) { flex: 1 0 50%; }
      &:nth-child(2) { flex: 1 0 50%; }
      &:nth-child(3) { flex: 1 0 100%; }
    }

    @media (max-width: (breakpoint("tiny") - 1px)) {
      &:nth-child(1) { flex: 1 0 100%; }
      &:nth-child(2) { flex: 1 0 100%; order: 3;}
      &:nth-child(3) { flex: 1 0 100%; order: 2;}
    }
  }
}

.heading {
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 1px solid #fff;
}

.linksWrapper {
  background: color("tertiary");
}

.links {
  display: flex;
  align-items: center;

  @media (max-width: (breakpoint("mini") - 1px)) {
    flex-wrap: wrap;

    li:last-child {
      width: 100%;
      margin: 0;
    }
  }

  a,
  button {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  li + li {
    margin-left: size("gutter");
  }
}

.paymentMethods {
  padding-top: size("gutter--mini");

  display: flex;
  flex-wrap: wrap;

  svg {
    flex: 1 0 50%;
    margin-bottom: size("gutter--large");
    height: 26px;
  }

  height: calc(100% - #{size("gutter--large")});

  .amex {
    height: 44px;
  }
}

.copyright {
  background: #fff;
  padding: size("gutter--large") 0;

  @media (max-width: (breakpoint("small") - 1px)) {
    font-size: size("font--mini");
    line-height: font("line-height");
    text-align: center;
  }

  &Wrapper {
    & > p {
      margin-bottom: 0;

      @media (max-width: (breakpoint("small") - 1px)) {
        display: inline-block;
        padding-bottom: size("gutter");
        border-bottom: 1px solid color("border");
      }
    }
  }
}