@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

:export {
  headerOffset: strip-unit(size("header-height") + size("header-margin"));
}

$grid-gutter: size("gutter--mini") / 2;

.block {
  margin-top: size("gutter--large") * 2;
}

.container {
  @media (min-width: breakpoint("medium")) {
    display: flex;
    align-items: flex-start;
    @include stretch($grid-gutter / 2);
  }
}

.left, .right {
  @media (min-width: breakpoint("medium")) {
    padding: 0 $grid-gutter;
  }
}

.left {
  flex: 1 1 auto;
  @media (min-width: breakpoint("medium")) {
    padding-right: size("gutter--large") * 2;
  }
}

.right {
  flex: 0 0 300px;
  @media (max-width: (breakpoint("medium") - 1px)) {
    margin-top: size("gutter--large") * 1.5;
  }
}

.box {
  padding: size("gutter--small") !important;

  h3 {
    color: color("text");
    font-size: size("font");

    svg {
      color: color("tertiary");
      margin-right: size("gutter--small");
    }
  }

  p, a, span {
    font-size: size("font--small");
  }

  a {
    text-decoration: underline;

    &:active:hover {
      opacity: 1;
    }

    &:focus:focus-visible {
      box-shadow: none !important;
    }
  }

  button {
    width: 100%;
  }
}
