@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";
@import "../../node_modules/@crossroads/ui-components/src/ui-components/CartMini.scss";

$miniCartWidth: 312px;

.miniCart {
  width: $miniCartWidth;

  @media (max-width: breakpoint("medium")) {
    right: size("gutter");
  }

  @media (max-width: breakpoint("tiny")) {
    right: size("gutter");
    left: size("gutter");
    width: auto !important;
  }
}

.altButton {
  border: 2px solid color("primary--alt") !important;
  color: color("secondary") !important;
  border-radius: 25px !important;

  transition-duration: transition('duration') !important;

  &:hover {
    background-color: color("primary--dark");
    border: 2px solid color("primary--dark") !important;
  }
}

.itemQty {
  text-align: end;
  font-weight: 700;
}
