@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$step-track-height: 16px;
$step-track-width: 564px;
$footer-height: 95px;

.block {
  // extra space in bottom to make up for bottom menu
  margin-bottom: size("gutter--large") * 3;

  @media (max-width: breakpoint("medium")) {
    margin-bottom: size("gutter--large") * 2;
  }

  padding-bottom: $footer-height;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: size("gutter--large") * 1.5;
}

.back {
  color: #fff !important;
  text-decoration: underline;
}

.heading {
  color: #fff;
  margin: 0;

  @media (max-width: breakpoint("small") - 1px) {
    font-size: size("font--medium");
  }
}

.footer {
  background: color("secondary");
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  padding: size("gutter--small") 0;

  border-top: 1px solid rgba(#fff, .2);

  @media (max-width: breakpoint("small") - 1px) {
    box-shadow: 0px 0 15px 0px rgba(0, 0, 0, .5);
  }

  z-index: #{z-index("system") - 1};

  display: flex;
  justify-content: center;

  transition: transform transition("duration--organisms") transition("ease-ease");
}

.back {
  color: #fff !important;
}

.footerInner {
  flex: 1 0 auto;
  padding: 0 size("gutter");
  max-width: calc(#{$step-track-width} + #{size("gutter") * 2});
}

.steps {
  @media (min-width: breakpoint("small")) {
    @include stretch(size("gutter--small"));
  }

  flex: 1 0 auto;
  display: flex;
  justify-content: space-between;
}

.step {
  z-index: 3;
  color: color("border") !important;
  padding: size("gutter--small");
  font-size: size("font--small");
  padding-top: size("gutter") + size("gutter--small");
  margin-top: -#{size("gutter--small")};

  user-select: none;

  &.active:not(.disabled) {
    color: #fff !important;
    text-decoration: underline;
  }

  &.active.current:not(.disabled) {
    font-weight: bold;
  }

  &.disabled {
    cursor: not-allowed;
    &:hover {
      text-decoration: none;
    }
    &:active {
      opacity: 1 !important;
    }
  }
}

.stepSlider {
  &Track {
    background: color("tertiary");
    max-width: $step-track-width;
    height: $step-track-height;
    border-radius: $step-track-height / 2;
  }

  &Value {
    background: color("primary");
    height: $step-track-height;
    border-radius: $step-track-height / 2;

    transition: width transition("duration--organisms") transition("ease-ease");

    &:before, &:after {
      content: "";
      z-index: 2;
      background: #fff;
    }

    &:before { left: 2px; }
    &:after { right: 2px; }

    .step__1 &:after {
      opacity: 0;
    }
  }
}

.stepSliderValue:before, .stepSliderValue:after, .sliderDot2, .sliderDot3 {
  position: absolute;
  top: 2px;

  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.sliderDot2, .sliderDot3 {
  z-index: 1;
  background: #0033CC;
}

.sliderDot2 {
  left: calc(53% - 2px);
  margin-left: -12px;
}

.step__3 .sliderDot2 {
  background: #fff;
}

.sliderDot3 {
  left: auto;
  right: 2px;
  margin-left: -6px;
}

.cantAfford {
  p {
    color: #fff;
  }
}
