@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$leftBlockWidthMedium: 310px;

.top {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.heading {
  margin-bottom: calc(size("gutter--large") * 2);
}

.logout {
  cursor: pointer;
  text-decoration: underline;
  color: color("primary");
}

.split {
  @media (min-width: breakpoint("small")) {
    display: flex;
    @include stretch(calc(size("grid-gutter") / 2));

    & > * {
      display: flex;
      flex-direction: column;

      flex: 1 0 33.33%;
      padding: 0 calc(size("grid-gutter") / 2);
    }
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    margin-bottom: size("gutter--large");

    & > *:not(:first-child) {
      margin-top: size("gutter--large");
    }
  }
}

.box {
  background: #F4F4F3;
  padding: size("gutter");
  border-radius: size("border-radius");

  & + & {
    margin-top: size("grid-gutter");
  }

  p {
    margin-top: 0;
    margin-bottom: size("gutter--mini");
    line-height: 1;
  }

  &Heading {
    color: color("text");
  }

  table {
    width: 100%;

    tr:not(:last-child) td {
      padding-bottom: size("gutter--mini");
    }

    td:first-child {
      font-weight: bold;
    }

    td:last-child {
      text-align: right;
    }
  }
}

.split2 {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    display: flex;
    align-items: center;
  }
}

.currency {
  color: color("text--muted");
  font-size: size("font--small");
  margin-left: size("gutter--mini");
}

.langIcon {
  margin-right: size("gutter--mini");
}
