@mixin row($padding: $gutter / 2) {
  display: flex;
  flex-wrap: wrap;
  padding: 0 $padding;
  @include clearfix;
  @include stretch;
}

@mixin stretch($distance: calc(size("grid-gutter") / 2)) {
  left: -#{$distance};
  width: calc(100% + #{$distance * 2});
  max-width: none;

  :global(.no-csscalc) & {
    left: auto;
  }
}

$opposites: (
  'top':    'bottom',
  'right':  'left',
  'bottom': 'top',
  'left':   'right',
);

@mixin SASHeading() {
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: font("uc-letter-spacing");
}

@mixin triangle($direction: top, $length: 1em, $color: #fff) {
  $active-sides: map-keys(map-remove($opposites, $direction));
  $opposite: map-get($opposites, $direction);

  content: '';
  display: block;

  width:  0;
  height: 0;

  position: absolute;
  #{$opposite}: 100%;
  margin-#{$opposite}: -1px;

  border: $length solid transparent;
  border-#{$direction}: 0;
  border-#{$opposite}-color: $color;
}


@mixin clearfix {
  &:after {
    content: '';
    clear: both;
    display: table;
  }
}

@mixin commonTransition {
  transition-property: color, background, border-color, opacity, transform, box-shadow, outline;
  transition-duration: transition("duration--molecules");

  &:active:hover {
    transition: none;
  }
}

%commonTransition {
  @include commonTransition();
}

@mixin momentumScroll {
  overflow-x: hidden;
  overflow-y: auto;

  :global([data-whatintent='touch']) & {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

@mixin momentumScrollX {
  overflow-y: hidden;
  overflow-x: auto;

  :global([data-whatintent='touch']) & {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

@mixin resetInput {
  border:     none;
  outline:    none !important;
  background: none;
  box-shadow: none;
  appearance: none;

  line-height:   1;
  border-radius: 0;
  padding:       0;
  margin:        0;

  cursor: text;
  color: inherit;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(#fff, 0);

  &:disabled {
    cursor: not-allowed;
  }
}

%resetInput {
  @include resetInput();
}

@mixin vertical-margins($margin) {
  margin-top:    $margin;
  margin-bottom: $margin;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin placeholderStyles {
  &::-webkit-input-placeholder { @content; }
  &::-moz-placeholder          { @content; }
  &:-ms-input-placeholder      { @content; }
}
