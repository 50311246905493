@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$icon-size: 25px;
$iconMarginX: size("gutter--small");

.block {
  width: 100%;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: color("OOC--color");
  height: size("header-height");
  text-transform: uppercase;

  @media (min-width: breakpoint("medium")) {
    display: none;
  }
}

.heading {
  display: block;
  color: color("secondary");
  font-size: size("font--medium");
  font-weight: bold;
}

.subHeader {
  color: color("text");
  font-size: size("font--medium");
}

.close, .submit {
  @include resetInput;
  cursor: pointer;
}

.container {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 4px;
  padding: 0;

  @media (max-width: (breakpoint("medium") - 1px)) {
    margin: size("gutter--small");
    border: 1px solid color("border");
  }
}

.icon {
  color: color("tertiary");
}

.searchIcon {
  flex: 0 1 auto;
  height: 33px;
  width: 33px;
  min-height: 33px;
  min-width: 33px;
  margin-left: $iconMarginX;
  margin-right: $iconMarginX;
}

.closeIcon {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  color: color("tertiary");
  margin-left: $iconMarginX;
  margin-right: $iconMarginX;
}

.inputWrapper {
  width: 100%;
  padding: calc(size("grid-gutter") / 2) 0;
}

.input {
  width: 100%;
  padding: size("gutter--small");
  border: none;
  border-left: 1px solid color("border");
  font-size: size("font") !important;
  background: none;
  cursor: text;

  @include placeholderStyles {
    color: color("text--muted");
  }

  &:focus {
    outline: none;
  }

  @media (min-width: breakpoint("medium")) {
    border-right: 1px solid color("border");
  }
}

.suggestionsSection {
  background: color("body");
  position: absolute;
  width: 100%;
  margin-top: 1px;
  border-radius: 4px;
  overflow: hidden;
  font-size: size("font");
}

.searchQuery {
  background: color("body--off");
  padding: 0 size("gutter--small");

  &Link {
    display: block;
    padding: size("gutter--small") 0;
    color: color("text");
  }
}

.suggestions {
  margin: 0 size("gutter--small");
}

.suggestion {
  &:not(:first-child) {
    border-top: 1px solid color("border");
  }

  &Link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: size("gutter--small") 0;
    color: color("text");
  }

  &Left, &Right {
    display: flex;
    align-items: center;
  }

  &Body {
    display: flex;
    justify-content: center;
  }

  span {
    color: color("text--muted");
  }
}
