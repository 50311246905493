@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.adventCalendarCategory {
    margin-top: -#{size("header-margin")};
}

.adventCalendarBack {
  margin: size("gutter--large") auto !important;

  display: flex;
  justify-content: center;
}