@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$grid-gutter: size("gutter--mini") / 2;

:export {
  headerOffset: strip-unit(size("header-height") + size("header-margin"));
}

.tier {
  padding: 4px 12px;
  border-radius: size("border-radius");

  &.level__S { background: color("member-level--silver"); color: color("text"); }
  &.level__G { background: color("member-level--gold"); color: color("text"); }
  &.level__D { background: color("member-level--diamond"); color: color("text"); }
}

.header {
  max-width: 850px;
  margin-bottom: size("gutter--large") * 1.2;
}

.description {
  color: color("text--muted");
}

.box {
  background: #fff;
  padding: size("gutter");
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.24);
  border-radius: size("border-radius");

  & + & {
    margin-top: size("gutter--large");
  }

  hr {
    border-color: color("checkout-fg-border");
  }
}

.options {
  @media (min-width: breakpoint("small")) {
    display: flex;
    margin: 0 -#{size("gutter--mini") / 2};

    & > div {
      display: flex;
      padding: 0 size("gutter--mini") / 2;
      flex: 1 0 33.33%;
    }
  }

  @media (max-width: (breakpoint("small") - 1px)) {
    & > div + div {
      margin-top: size("gutter");
    }
  }
}

.option {
  transition: opacity transition("duration--molecules") transition("ease-ease");

  &:hover {
    text-decoration: none !important;
    opacity: .9;
  }

  display: flex;
  flex-direction: column;
  text-decoration: none;
  text-align: center;
  background: color("secondary");
  padding: size("gutter");
  width: 100%;

  h3, p {
    color: #fff;
  }

  &Top {
    margin-bottom: size("gutter");
    flex: 1 0 auto;
  }
}

.button {
  display: inline-block;
  color: #fff;
  border: 2px solid #fff;
  font-weight: 800;
  text-transform: uppercase;
  border-radius: size("border-radius");
  padding: size("gutter--mini") size("gutter--large") * 2;

  @media (max-width: (breakpoint("tiny") - 1px)) {
    width: 100% !important;
  }
}

.basicPoint {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  text-align: center;
  background: color("secondary");
  padding: size("gutter");
  width: 100%;
  background: color("disabled");

  &Top {
    h3 {
      color: color("border");
    }
  }

  &Body {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    p {
      color: black;
    }

    @media (max-width: (breakpoint("tiny") - 1px)) {
      margin: size("gutter") 0;
    }
  }
  
  &Bottom {
    margin-bottom: size("gutter--medium");
    a {
      text-decoration: underline;
    }
  }
}