@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  background: #fff;
  padding: size("gutter");
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.24);
  border-radius: size("border-radius");

  & + & {
    margin-top: size("gutter--mini");
  }

  hr {
    border-color: color("checkout-fg-border");
  }
}

