@import "../../node_modules/@crossroads/ui-components/src/ui-components/ProductViewMedia.scss";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$item-width: 76px;
$image-max-width: 800px; // The product image size on the server is 800x80

.gallery {
  &Item {
    max-width: calc(#{$item-width} + #{size("grid-gutter")});
    img {
      border: 1px solid color("border--muted");
      border-radius: size("border-radius");
    }
  }
}

.lightbox {
  &Image {
    max-width: $image-max-width;
  }

  &Gallery {
    &Item {
      width: $item-width;
      height: $item-width;
    }
  }

  &Close {
    width: $item-width;
    height: $item-width;
  }

  .galleryItem {
    width: $item-width;
  }
}
