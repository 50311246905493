@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$lightboxMaxWidth: 400px;
$closeIconSideLength: 48px;
$closeIconOffSet: .7rem;
$paddingBottomWrapper: 88px;

.block {
  max-height: 100vh;
  overflow-y: auto;
}

.wrapper {
  text-align: center;
  padding-bottom: $paddingBottomWrapper;

  h2 {
    font-size: size("font--huge");

    @media (max-width: breakpoint("tiny") - 1px) {
      font-size: size("font--large");
    }
  }
}

.headings {
  padding: size("gutter--large") ;
  padding-top: size("gutter--medium") * 4;
  padding-bottom: size("gutter--medium") * 1.5;
}

.lightbox {
  padding: 0 !important;

  @media (min-width: breakpoint("small")) {
    max-width: $lightboxMaxWidth;
  }
}

.closeIcon {
  position: absolute;
  top: $closeIconOffSet;
  right: $closeIconOffSet;
  width: $closeIconSideLength;
  height: $closeIconSideLength;
  cursor: pointer;
  padding: size("gutter--mini");
  color: color("tertiary");
  z-index: 1;
}
