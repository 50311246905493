@import "../../node_modules/@crossroads/shop-views/src/shop-views/AdventCalendar.scss";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$contentPaddingBottom: size("gutter--mini") + size("gutter--large") * 2;
$containerPadding: 16rem;
$containerPaddingMini: 19rem;
$containerPaddingTiny: 22rem;

:export {
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
}

.today {
  &Container {
    display: flex;
    flex-direction: column;
  }

  &Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    flex: 1 1 auto;
    margin-top: size("gutter--large") * 1.2;
    padding-bottom: $contentPaddingBottom;

    h3 {
      font-size: size("font--huge");
      margin-bottom: 0;
    }

    h2 {
      font-size: size("font--enormous");
      margin-top: 0;
      margin-bottom: size("gutter--small");
    }
  }

  &Description {
    padding: size("gutter--mini");
    max-width: 500px;
    margin-bottom: size("gutter--large") * 1.5;
    background: color("secondary");
    color: #fff;
    text-align: center;
    @include SASHeading;
  }

  &ProductList {
    margin-top: size("gutter");

    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }

  &ViewAll {
    margin: size("gutter") size("gutter") size("header-margin");
    display: block;
    text-align: center;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.button {
  pointer-events: none;

  &:hover {
    background: color("primary") !important;
  }

  .door:active & {
    color: rgba(#fff, .5);
    background-color: color("primary", -10%);
    background-size: 100%;
    transition: background 0s;
  }

  .door:hover & {
    background-color: color("primary", 10%);
    background: color("primary", 10%) radial-gradient(circle, transparent 1%, color("primary", 10%) 1%) center/15000%;
  }
}

.landing {
  margin-top: -#{size("header-margin")};
  background-color: color("secondary");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: breakpoint("tiny-small")) {
    background-position: right;
  }

  &>* {
    flex: 1 1 auto;

    @media (min-width: breakpoint("tiny-small")) {
      background-position: right center;
      background-size: 450px;
    }
  }

  &.fullSize {
    min-height: calc(100vh - #{size("header-height")});
  }

  @media (min-width: breakpoint("tiny-small")) {
    display: flex;
    align-items: center;
  }

  @media (max-width: (breakpoint("tiny-small") - 1px)) {
    padding-top: size("gutter");
  }

  &,
  h1 {
    color: #fff;
  }

  h1 {
    margin-bottom: size("gutter");

    @media (max-width: (breakpoint("small") - 1px)) {
      font-size: size("font--huge");
    }
  }

  p {
    margin-top: 0;
    margin-bottom: size("gutter--large");
    max-width: 350px;
  }

  &Container {
    display: flex;
    flex-direction: column;
    padding: $containerPadding 0;
    padding-bottom: 0;

    &.homeView {
      @media (max-width: breakpoint("tiny")) {
        padding: 0;
      }
    }

    @media (min-width: breakpoint("mini")) {
      padding: $containerPaddingMini 0;
      padding-bottom: 0;
    }

    @media (min-width: breakpoint("tiny")) {
      padding: $containerPaddingTiny 0;
      padding-bottom: 0;
    }

    @media (min-width: breakpoint("tiny-small")) {
      padding: size("gutter--section") 0;
    }

    @media (max-width: (breakpoint("medium") - 1px)) {
      flex-direction: column;
    }
  }
}
  
.block {
.openButton {
    width: 100%;
    background-color: color("primary");
    pointer-events: all;
    cursor: pointer;
    padding: size("gutter") size("gutter--small");
    font-weight: bold;
    text-transform: uppercase;
    font-size: size("font--small");

    @media (min-width: breakpoint("tiny-small")) {
    display: none;
    }
}

.header {
    @media (max-width: (breakpoint("tiny-small") - 1px)) {
    padding-top: size("gutter");
    }

    p:last-of-type {
    margin-bottom: 0;
    }
}
}

.form {
margin-top: size("gutter");
padding-bottom: size("gutter--large");

@media (min-width: breakpoint("tiny-small")) {
    background: #fff;
    border-radius: size("border-radius");
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    max-width: 520px;
    padding-bottom: 0;
}

& > *:first-child {
    flex: 1 0 auto;
}
}

.input {
input {
    color: color("text");

    @media (min-width: breakpoint("tiny-small")) {
    border: none !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    }
}
}

.mobile {
@media (min-width: breakpoint("tiny-small")) {
    display: none !important;
}
}

.desktop {
@media (max-width: (breakpoint("tiny-small") - 1px)) {
    display: none !important;
}
}

.button {
border-width: 2px !important;
text-transform: uppercase;
font-weight: 800 !important;

@media (min-width: breakpoint("tiny-small")) {
    height: auto !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-right: 10px !important;
}

@media (max-width: (breakpoint("tiny-small") - 1px)) {
    margin-top: size("gutter") !important;
    width: 100%;
}
}
