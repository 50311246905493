@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

:export {
  mini: strip-unit(breakpoint("mini"));
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

$top-space: 35px;
$top-space--mobile: 25px;
$grid-gutter: size("gutter--small");

.block {
  margin-top: -#{size("header-margin")};

  // negate app footer margin and apply here instead to keep background color
  padding-bottom: #{size("gutter--large") * 3};
  margin-bottom: -#{size("gutter--large") * 3};
}

.row {
  & + & {
    margin-top: $grid-gutter * 2;
  }
}

.col2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: breakpoint("small")) {
    margin-left: -#{size("gutter--mini")};
    margin-right: -#{size("gutter--mini")};
  }

  @media (max-width: breakpoint("small") - 1px) {
    flex-direction: column;
  }
}

.column {
  flex: 0 0 auto;
  width: 33.333%;
  padding-bottom: size("gutter--mini") * 2;

  .h2 {
    font-size: size("font--medium");
  }

  @media (max-width: breakpoint("medium") - 1px) {
    width: 50%;
  }

  @media (min-width: breakpoint("small")) {
    padding-right: size("gutter--mini");
    padding-left: size("gutter--mini");
  }

  @media (max-width: breakpoint("small") - 1px) {
    width: 100%;

    & + & {
      padding-top: $grid-gutter * 2;
    }
  }
}

.hero1 {
  height: 430px;
}

.blockHero {
  height: 450px;
}

.blockSplitHero {
  height: 400px;
}

.promo {
  @media (min-width: breakpoint("small")) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  padding: size("gutter--medium");
  background: color("primary");
  color: #fff;

  h2 {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: size("gutter--mini");
  }

  p {
    margin-top: 0;
  }

  button {
    @media (max-width: breakpoint("small") - 1px) {
      margin-top: size("gutter");
    }
  }
}

.heading {
  text-transform: uppercase;
  font-weight: bold;
}

.popularCategories, .popularProducts {
  content-visibility: auto;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: size("gutter--large");

    h2 {
      margin-bottom: 0;
    }
  }
}

.ctaDesktop {
  display: block;
  color: color("primary");
  text-decoration: underline;
  cursor: pointer;
  flex: 0 1 auto;
  text-decoration: underline;

  @media (max-width: breakpoint("tiny-small") - 1px) {
    display: none;
  }
}

.ctaMobile {
  width: 100%;
  margin: size("gutter--large") auto size("gutter") 0 !important;

  @media (min-width: breakpoint("tiny-small")) {
    display: none !important;
  }
}

.brands {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.popularBrands, .popularCategories {
  content-visibility: auto;
  margin-top: size("gutter--section") / 1.6;

  @media (max-width: breakpoint("small") - 1px) {
    h2 {
      font-size: size("font--medium");
    }
  }

  @media (max-width: breakpoint("tiny-small") - 1px) {
    .categories, .brands, .products, h2 {
      order: -1;
    }
  }
}

.popularProducts, .recentlyViewedProducts {
  content-visibility: auto;
  margin-top: size("gutter--section");

  @media (max-width: breakpoint("small") - 1px) {
    h2 {
      font-size: size("font--medium");
    }
  }
}
