@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  @media (min-width: breakpoint("medium")) {
    @include stretch(size("gutter--large") * 2);
  }

  p {
    font-size: size("font--small");
    color: color("text--muted");
  }
}

.header {
  display: flex;
  justify-content: space-between;

  margin-bottom: size("gutter--large") * 2;
}

.mainTitle {
  display: flex;
  align-items: center;
}

.title {
  // font-size: size("font--medium");
  // margin-bottom: 0;
  // color: color("text");
}

.subHeading {
  color: color("text");
  font-size: size("font--medium");
  margin-bottom: size("gutter--small");
}

.orderNo {
  margin-top: 0;
  text-transform: none;
  font-weight: 400;
  color: color("text--muted");
  font-size: size("font--medium");
}

.checkIcon {
  width: 24px;

  &BG {
    background: color("positive");
    color: #fff;
    margin-right: size("gutter");
    border-radius: 50%;
    padding: 12px;
  }
}

.back {
  @media (max-width: breakpoint("small") - 1px) {
    display: none;
  }
}

.customerService {
  margin-bottom: size("gutter--large") * 2;
}

.left {
  @media (min-width: breakpoint("medium")) {
    float: left;
    padding: 0 size("gutter--large") * 2;
    width: 45%;
  }
}

.right {
  @media (min-width: breakpoint("medium")) {
    float: right;
    padding: 0 size("gutter--large") * 2;
    width: 55%;
  }

  @media (max-width: breakpoint("medium") - 1px) {
    margin-bottom: size("gutter--large") * 2;
  }
}

.box {
  padding: size("gutter--large");
  border-radius: size("border-radius");
  box-shadow: 0 2px 5px #d5d4d1;

  h3 {
    color: color("text");
    font-size: size("font--small");
  }

  &Section {
    &:not(:last-child) {
      margin-bottom: size("gutter--large");
      padding-bottom: size("gutter--large");
      border-bottom: 1px solid #d5d4d1;
    }
  }
}

.tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: size("font--small");

  & > span:first-child {
    color: color("text--muted");
  }

  & + & {
    margin-top: size("gutter");
  }
}
