@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$grid-gutter: size("gutter--mini") / 2;

.block {
  padding: 0 !important;
  overflow: hidden;
  font-size: size("font--small") !important;

  button {
    width: 100%;
  }

  hr {
    border-color: color("checkout-fg-border");

    margin-bottom: size("gutter--medium");
  }
}

.body {
  padding: size("gutter--small");

  .section:not(:first-of-type) {
    border-top: 1px solid color("border");;
    padding-top: size("gutter--medium");
    margin-top: size("gutter--medium");
  }

  .section.stripe {
    padding-top: 0
  }
}

.heading {
  font-size: size("font--small");

  &, & > * {
    color: color("text") !important;
  }
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tr {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > *:last-child {
    color: color("text--muted");
    text-align: right;
    line-height: font("line-height");
  }

  & + & {
    margin-top: size("gutter--mini") * 1.4;
  }
}

.recipient {
  .tr {
    flex-direction: column;
    align-items: flex-start;

    span {
      display: block;
    }

    span:not(:last-child) {
      margin-bottom: size("gutter--mini") * 0.7;
    }
  }
}

.stripe {
  &Error {
    margin: size("gutter--small") 0;
    margin-bottom: 0;
    color: color("negative");
  }

  &Row {
    & + & {
      margin-top: size("gutter") / 2;
    }
  }

  &Split {
    @include stretch();
    @include clearfix();
  }

  &Left, &Right {
    width: 50%;
    float: left;
    padding: 0 size("grid-gutter") / 2;
  }

  &Button {
    display: block;
    width: 100%;
    margin-top: size("gutter") !important;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: size("gutter");

  .heading {
    margin-bottom: 0;
  }

  & > span {
    text-decoration: underline !important;
    font-size: size("font--mini");
  }
}

.orParagraph {
  display: block;
  text-align: center;
  padding: size("gutter") 0;
}

.cash {
  display: block;
  font-weight: normal;
}

.paymentMethods > div {
  padding-top: 0 !important;
}
